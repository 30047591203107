import {
  loadCurrencies,
  loadCurrencyRates,
  loadSubscription,
  loadAuthorizations,
  loadAccounts,
  loadGroups,
  loadGroupInfo,
  loadSettings,
  loadPlans,
  loadFeatures,
  loadAssetClasses,
  loadModelPortfolios,
  loadEmailVerified,
  loadSnapTradePartnerData,
} from '../actions';
import {
  loadDefaultActivities,
  loadGpData,
  loadPerformanceAll,
  loadReportingSettings,
} from '../actions/performance';
import { checkIfOnline } from '../actions/online';
import {
  selectCurrencyRatesNeedData,
  selectAuthorizationsNeedData,
  selectSettingsNeedData,
  selectPlansNeedData,
} from '../selectors';
import { selectAccountsNeedData } from '../selectors/accounts';
import { selectAssetClassesNeedData } from '../selectors/assetClasses';
import { selectModelPortfoliosNeedData } from '../selectors/modelPortfolios';
import {
  selectGroupsNeedData,
  selectGroupInfoNeedsData,
} from '../selectors/groups';

import { selectSubscriptionNeedData } from '../selectors/subscription';
import { selectShouldCheckIfOnline } from '../selectors/online';
import {
  selectActivitiesNeedData,
  selectGpDataNeedData,
  selectPerformanceNeedData,
  selectReportingSettingsNeedData,
} from '../selectors/performance';
import { selectGoalsNeedData } from '../selectors/goals';
import { loadGoals } from '../actions/goals';

import { selectFeaturesNeedData } from '../selectors/features';
import { selectCurrenciesNeedData } from '../selectors/currencies';
import { selectIsEmailVerifiedNeedData } from '../selectors/emailVerified';
import { selectSnapTradePartnerDataNeedData } from '../selectors/snapTrade';

export { default as reducer } from '../reducers';

export const effects = [
  {
    selector: selectCurrenciesNeedData,
    actionCreator: loadCurrencies,
  },
  {
    selector: selectFeaturesNeedData,
    actionCreator: loadFeatures,
  },
  {
    selector: selectCurrencyRatesNeedData,
    actionCreator: loadCurrencyRates,
  },
  {
    selector: selectSubscriptionNeedData,
    actionCreator: loadSubscription,
  },
  {
    selector: selectAuthorizationsNeedData,
    actionCreator: loadAuthorizations,
  },
  {
    selector: selectAccountsNeedData,
    actionCreator: loadAccounts,
  },
  {
    selector: selectGroupsNeedData,
    actionCreator: loadGroups,
  },
  {
    selector: selectGroupInfoNeedsData,
    actionCreator: loadGroupInfo,
  },
  {
    selector: selectSettingsNeedData,
    actionCreator: loadSettings,
  },
  {
    selector: selectPlansNeedData,
    actionCreator: loadPlans,
  },
  {
    selector: selectPerformanceNeedData,
    actionCreator: loadPerformanceAll,
  },
  {
    selector: selectReportingSettingsNeedData,
    actionCreator: loadReportingSettings,
  },
  {
    selector: selectShouldCheckIfOnline,
    actionCreator: checkIfOnline,
  },
  {
    selector: selectAssetClassesNeedData,
    actionCreator: loadAssetClasses,
  },
  {
    selector: selectModelPortfoliosNeedData,
    actionCreator: loadModelPortfolios,
  },
  {
    selector: selectGoalsNeedData,
    actionCreator: loadGoals,
  },
  {
    selector: selectActivitiesNeedData,
    actionCreator: loadDefaultActivities,
  },
  {
    selector: selectGpDataNeedData,
    actionCreator: loadGpData,
  },
  {
    selector: selectIsEmailVerifiedNeedData,
    actionCreator: loadEmailVerified,
  },
  {
    selector: selectSnapTradePartnerDataNeedData,
    actionCreator: loadSnapTradePartnerData,
  },
];
